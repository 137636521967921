import React from "react"
import Layout from "../components/Layout";
import QAAccordion from "../components/QAAccordion";

export default function Help() {

    let pricies = [
        {"question":"料金はいくらですか？", "answer":"男性会員様は有料、女性会員様は無料となっております。"},
        {"question":"男性が有料会員になった場合は何ができますか？", "answer":"マッチング・マッチングしたお相手とのチャットができるようになります。"},
        {"question":"有料会員の次回更新日を知りたいです/有料会員を解約したいのですが/有料会員を途中で解約した場合、返金はありますか？", "answer":"有料会員は、以下の方法で次回更新日を確認したり、解約をすることができます\n・IOSアプリの定期購入解約方法\n有料会員の途中で解約しても、購入済みの有料会員期間が終わるまでは有料会員のサービスを受けられますが、支払い済みの料金の返金はありません\n退会・アプリのアンインストール等によって定期購入が解約されることはありませんのでご注意ください"}
    ]

    let registrations = [
        {"question":"身分証が認証できません・本人確認ができません", "answer":"身分証を提出したのに再提出を求められてしまう場合、多くは以下が原因となります\n・身分証の一部が隠れている・不鮮明\n・身分証に住所の記載がない\n・身分証の生年月日と、アプリの登録した生年月日が不一致\n・身分証の期限が切れている\n・身分証のコピーやスキャンの画像を提出している"},
        {"question":"登録内容を変更したいです", "answer":"ホームの「プロフィールの確認・編集」から変更ができます"}
    ]

    let matting = [
        {"question":"マッチングではどんな項目が重視されていますか？", "answer":"お互いの「受け入れてほしいこと」を「受け入れられる」人を優先して候補を提示しております。\nまた、希望の条件が一致しているかと興味と関心の一致、チャット後の感想から総合的にお相手とマッチングしております"},
        {"question":"どうしてお相手の顔写真が見えないのですか？", "answer":"顔写真から好みのお相手を選んでしまうと、「受け入れあえる」お相手を見つけることが難しくなってしまうため、顔写真は乗せらないようになっております"},
        {"question":"マッチング後のキャンセルはできますか？", "answer":"チャットページから、「？」→「お断りする」を選択することでキャンセルすることができます"},
        {"question":"マッチングしたはずの相手が消えてしまいました", "answer":"お相手によるマッチングの終了、退会があった場合は、マッチングが終了することがあります"},
    ]

    let chats = [
        {"question":"どうして写真を送ることができないのですか？", "answer":"顔写真の要求などを避けるためにメッセージのみでのやりとりになっています"},
        {"question":"連絡先を交換してもいいですか？", "answer":"メッセージ内で双方の合意があれば、電話番号等の交換をすることが可能です"},
        {"question":"お相手とチャットができなくなりました", "answer":"お相手によるマッチングの終了、退会があった場合は、マッチングが終了することがあります"},   
        {"question":"以前マッチングしたお相手とのチャットが消えました", "answer":"メッセージの保存期間は６ヶ月となります"},

    ]

    let stops = [
        {"question":"一時的に利用を停止したいです", "answer":"「設定」→「一時的に利用を停止する」からマッチングを止めることができます。\nまた、再開する際は「設定」→「一時的に利用を解除する」でマッチングを再開することができます。"},
        {"question":"休会中にお相手との連絡はできますか？", "answer":"「一時的に利用を停止する」を設定中でも、現在マッチングしているお相手とのメッセージは継続できます。"},
    ]

    return (
        <>
        <Layout>
            <div className="column is-10  is-offset-1">
            <h1>よくあるご質問</h1>
            <h2>料金</h2>
            <QAAccordion question={pricies[0].question} answer={pricies[0].answer} />
            <QAAccordion question={pricies[1].question} answer={pricies[1].answer} />
            <QAAccordion question={pricies[2].question} answer={pricies[2].answer} />

            <h2>登録内容の変更等</h2>
            <QAAccordion question={registrations[0].question} answer={registrations[0].answer} />
            <QAAccordion question={registrations[1].question} answer={registrations[1].answer} />

            <h2>マッチング</h2>
            <QAAccordion question={matting[0].question} answer={matting[0].answer} />
            <QAAccordion question={matting[1].question} answer={matting[1].answer} />
            <QAAccordion question={matting[2].question} answer={matting[2].answer} />
            <QAAccordion question={matting[3].question} answer={matting[3].answer} />

            <h2>チャット</h2>
            <QAAccordion question={chats[0].question} answer={chats[0].answer} />
            <QAAccordion question={chats[1].question} answer={chats[1].answer} />
            <QAAccordion question={chats[2].question} answer={chats[2].answer} />
            <QAAccordion question={chats[3].question} answer={chats[3].answer} />

            <h2>休会・退会</h2>
            <QAAccordion question={stops[0].question} answer={stops[0].answer} />
            <QAAccordion question={stops[1].question} answer={stops[1].answer} />
            </div>
        </Layout>
        </>
    )
}

