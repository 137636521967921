import React, { useState } from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';




const QAAccordion = ({question, answer}) => {

    return (
        <div>
            
 
                <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{fontWeight:"bold"}}
                  >{question}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{background:"rgb(19,200,110)"}}>
                  <Typography style={{
                      color:"white",
                      fontWeight:"bold",
                      whiteSpace:"pre-wrap"
                      }} >
                    {answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
      </div>    
    )
}

export default QAAccordion;